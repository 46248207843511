@use './custom-palettes' as palettes;

.notification-panel-error {
  --mdc-snackbar-container-color: #{map-get(palettes.$custom-red, 50)};

  .mat-divider {
    background-color: #{map-get(palettes.$custom-red, 100)};
  }
}

.notification-panel-info {
  --mdc-snackbar-container-color: #{map-get(palettes.$custom-blue, 100)};

  .mat-divider {
    background-color: #{map-get(palettes.$custom-blue, 200)};
  }
}

.notification-panel-succes {
  --mdc-snackbar-container-color: #{map-get(palettes.$custom-green, 50)};

  .mat-divider {
    background-color: #{map-get(palettes.$custom-green, 100)};
  }
}

.notification-panel-warn {
  --mdc-snackbar-container-color: #{map-get(palettes.$custom-yellow, 50)};

  .mat-divider {
    background-color: #{map-get(palettes.$custom-yellow, 100)};
  }
}
