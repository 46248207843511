@use '@angular/material' as mat;
@use './styles/app-theme' as appTheme;
@use './styles/common';
@use './styles/color-helpers';
@use './styles/outer-helpers';
@use './styles/notification_palletes';
@use './styles/variables';

@include mat.core();

@include mat.all-component-themes(appTheme.$smart-music-fe-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, 'Helvetica Neue', sans-serif;
}
