@use '@angular/material' as mat;
@use './app-theme' as appTheme;
$theme: appTheme.$smart-music-fe-theme;

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.container {
  height: 100%;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  overflow: auto;
  background-color: mat.get-theme-color($theme, primary, 50);
}

.small-label {
  font-size: 0.9rem;
  line-height: 1rem;
  vertical-align: baseline;
  font-weight: 400;
}

.flex {
  display: flex;
}

.flex-centered {
  display: flex !important;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.spacer {
  margin: auto;
}

.no-out-footprint {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.songlistfilter-custom-select-panel {
  padding-top: 0px !important;
  margin-top: 8px !important;
}

.app-user-info-menu {
  --mat-menu-container-shape: 12px;
  --mat-menu-item-icon-color: #{mat.get-theme-color($theme, accent, default)};
  --mat-menu-item-label-text-color: #{mat.get-theme-color($theme, accent, default)};
  min-width: 180px !important;
  margin-top: 8px;
}

.mat-mdc-snack-bar-container.mdc-snackbar__surface {
  box-shadow: 0px 4px 12px 0px #a7aec4b2;
  border-radius: 20px;
}
